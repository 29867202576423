import { Routes } from '@angular/router';

import { ApiPage, GotoPage, ConfirmPage } from '@btlib-core';

// module'\).then\(m => m.(.+)Module\)
// page').then(m => m.$1)
export const routesCommon: Routes = [

  
  {
    path: 'subuser',
    children: [
      {
        path: 'provider',
        children: [
          { path: 'index', loadComponent: () => import('./pages/subuser/provider/index/index.page').then(m => m.IndexPage)  },
          { path: 'overview', loadComponent: () => import('./pages/subuser/provider/order-overview/order-overview.page').then(m => m.OrderOverviewPage)  },  
        ]
      },
    ]
  },

  {
    path: 'misc',
    children: [
      { path: 'discount-codes', loadComponent: () => import('./pages/misc/discount-codes/discount-codes.page').then(m => m.DiscountCodesPage)  },
      { path: 'bank-integration', loadComponent: () => import('./pages/misc/bank-integration/bank-integration.page').then(m => m.BankIntegrationPage)  },
      { path: 'misc-stats', loadComponent: () => import('./pages/misc/misc-stats/misc-stats.page').then(m => m.MiscStatsPage)  },
      { path: 'reports', loadComponent: () => import('./pages/misc/reports/reports.page').then(m => m.ReportsPage)  },
      { path: 'stats', loadComponent: () => import('./pages/misc/stats/stats.page').then(m => m.StatsPage)  },
      { path: 'tasks', loadComponent: () => import('./pages/misc/tasks/tasks.page').then(m => m.TasksPage)  },
      { path: 'tools', loadComponent: () => import('./pages/misc/tools/tools.page').then(m => m.ToolsPage)  },
      { path: 'links', loadComponent: () => import('./pages/misc/links/links.page').then(m => m.LinksPage)  },
      
      { path: 'app-config', loadComponent: () => import('./pages/misc/app-config/app-config.page').then(m => m.AppConfigPage)  },
      { path: 'app-content', loadComponent: () => import('./pages/misc/app-content/app-content.page').then(m => m.AppContentPage)  },
      
      { path: 'testing', loadComponent: () => import('./pages/misc/testing/testing.page').then(m => m.TestingPage)  },
     
    ]
  },

  {
    path: 'order',
    children: [
      { path: 'dashboard', loadComponent: () => import('./pages/order/dashboard/dashboard.page').then(m => m.DashboardPage)  },
      { path: 'overview', loadComponent: () => import('./pages/order/overview/overview.page').then(m => m.OverviewPage)  },
      { path: 'orders', loadComponent: () => import('./pages/order/orders/orders.page').then(m => m.OrdersPage)  },
      { path: 'order/:id', loadComponent: () => import('./pages/order/order/order.page').then(m => m.OrderPage)  },
      { path: 'chat', loadComponent: () => import('./pages/order/chat/chat.page').then(m => m.ChatPage)  },
      

    ]
  },

  {
    path: 'product',
    children: [
      { path: 'products', loadComponent: () => import('./pages/product/products/products.page').then(m => m.ProductsPage)  },
      { path: 'product/:id', loadComponent: () => import('./pages/product/product/product.page').then(m => m.ProductPage)  },

      { path: 'areas', loadComponent: () => import('./pages/product/areas/areas.page').then(m => m.AreasPage)  },
      { path: 'product-types', loadComponent: () => import('./pages/product/product-types/product-types.page').then(m => m.ProductTypesPage)  },
      { path: 'property-types', loadComponent: () => import('./pages/product/property-types/property-types.page').then(m => m.PropertyTypesPage)  },
   
    ]
  },

  {
    path: 'user',
    children: [
      { path: 'users', loadComponent: () => import('./pages/user/users/users.page').then(m => m.UsersPage)  },
      { path: 'user', loadComponent: () => import('./pages/user/user/user.page').then(m => m.UserPage)  },
      
    ]
  },

  {
    path: 'provider',
    children: [
      { path: 'order', loadComponent: () => import('./pages/provider/order/order.page').then(m => m.OrderPage)  },
      { path: 'confirm-orders', loadComponent: () => import('./pages/provider/confirm-orders/confirm-orders.page').then(m => m.ConfirmOrdersPage)  },
      
      {
        path: 'misc',
        children: [
          { path: 'electricity-billing', loadComponent: () => import('./pages/provider/misc/electricity-billing/electricity-billing.page').then(m => m.ElectricityBillingPage)  },
          { path: 'gift-card', loadComponent: () => import('./pages/provider/misc/gift-card/gift-card.page').then(m => m.GiftCardPage)  },
        ]
      },

    ]
  },

  {
    path: 'agent',
    children: [
      { path: '', loadComponent: () => import('./pages/agent/index/index.page').then(m => m.IndexPage)  },
      { path: 'order', loadComponent: () => import('./pages/agent/order/order.page').then(m => m.OrderPage)  },
      
    ]
  },
  
  {
    path: 'superuser',
    children: [
      { path: 'properties', loadComponent: () => import('./pages/superuser/properties/properties.page').then(m => m.PropertiesPage)  },

    ]
  },
  
  {
    path: 'app',
    children: [

      {
        path: 'spacebooking',
        children: [
        
          { path: 'admin', loadComponent: () => import('./pages/app/spacebooking/spacebooking-admin/spacebooking-admin.page').then(m => m.SpacebookingAdminPage)  },

        ]
      },
      
      {
        path: 'tasks',
        children: [
        
          { path: 'tasks', loadComponent: () => import('./pages/app/tasks/tasks/tasks.page').then(m => m.TasksPage)  },
          { path: 'add', loadComponent: () => import('./pages/app/tasks/task-add/task-add.page').then(m => m.TaskAddPage)  },

        ]
      },


      {
        path: 'bergensportsreiser',
        children: [
          { path: 'matches', loadComponent: () => import('./pages/app/bergensportsreiser/matches/matches.page').then(m => m.MatchesPage)  },
          { path: 'orders', loadComponent: () => import('./pages/app/bergensportsreiser/orders/orders.page').then(m => m.OrdersPage)  },
          { path: 'order', loadComponent: () => import('./pages/app/bergensportsreiser/order/order.page').then(m => m.OrderPage)  },
          // { path: 'overview', loadComponent: () => import('./pages/subuser/provider/order-overview/order-overview.page').then(m => m.OrderOverviewPage)  },
          
          { path: 'reports', loadComponent: () => import('./pages/app/bergensportsreiser/reports/reports.page').then(m => m.ReportsPage)  },
         
          // {
          //   path: 'reports',
          //   children: [
            
          //     { path: 'misc', loadComponent: () => import('./pages/app/bergensportsreiser/reports/misc/misc.page').then(m => m.MiscPage)  },
    
          //   ]
          // },
        ]
      },


      {
        path: 'sjofiske',
        children: [
        
          { path: 'overview', loadComponent: () => import('./pages/app/sjofiske/overview/overview.page').then(m => m.OverviewPage)  },

        ]
      },

      {
        path: 'langedrag',
        children: [
        
          { path: 'overview', loadComponent: () => import('./pages/app/langedrag/overview/overview.page').then(m => m.OverviewPage)  },

        ]
      },



    ]
  },
  
  {
    path: "",
    loadComponent: () => import("./pages/index/index.page").then((m) => m.IndexPage),
    
  },
  {
    path: "template-page",
    loadComponent: () => import("./pages/template-page/template-page.page").then((m) => m.TemplatePagePage),
    
  },
  {
    path: "lib",
    children: [
      { path: "api", component: ApiPage },
      { path: "goto", component: GotoPage },
      { path: "user", children: [
        { path: "confirm", component: ConfirmPage },
      ]},
    ]
  }



];


export const routes: Routes = [

  ...routesCommon,
    
  {
    path: 'api/:cid',
    children: routesCommon
  },

  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

